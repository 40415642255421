import { MathService } from 'src/services';

class AnimationService {
	#timingFunctions = {
		'linear': [0, 0, 1, 1],
		'ease': [0.25, 0.1, 0.25, 1]
	};
	#parameters = { duration: 0, timingFunction: this.#timingFunctions.ease };
	#properties = [];
	#hooks = {};
	#inProcess = false;

	setProperties(properties) {
		this.#properties = properties;
	}

	setParameters(parameters) {
		this.#parameters = { ...this.#parameters, ...parameters };

		if (this.#parameters.timingFunction?.constructor !== Array) {
			if (this.#parameters.timingFunction?.constructor === String && this.#parameters.timingFunction in this.#timingFunctions) {
				this.#parameters.timingFunction = this.#timingFunctions[this.#parameters.timingFunction];
			} else this.#parameters.timingFunction = this.#timingFunctions.ease;
		}
	}

	on(events, callback) {
		events = typeof events === 'string' ? [events] : events;

		for (const event of events) {
			if (!(event in this.#hooks)) this.#hooks[event] = [];
			this.#hooks[event].push(callback);
		}
	}

	start() {
		if ('step' in this.#hooks) {
			this.#inProcess = true;

			const startTime = Date.now();
			const requestAnimation = 'requestAnimationFrame' in window ? window.requestAnimationFrame : (cb) => setTimeout(cb);

			(function animation() {
				if (this.#inProcess) {
					const spendTime =  Math.min(Date.now() - startTime, this.#parameters.duration);
					const x = spendTime / this.#parameters.duration;
					const t = MathService.solveCubic(
						3 * this.#parameters.timingFunction[0] - 3 * this.#parameters.timingFunction[2] + 1,
						- 6 * this.#parameters.timingFunction[0] + 3 * this.#parameters.timingFunction[2],
						3 * this.#parameters.timingFunction[0],
						-x
					).filter((t) => t >= 0)[0] || 0;
					const y = MathService.bezierCubic(t, 0, this.#parameters.timingFunction[1], this.#parameters.timingFunction[3], 1);
					const properties = this.#properties.map((p) => [p[0], p[1][0] + (p[1][1] - p[1][0]) * y]);

					for (const callback of this.#hooks.step) callback(properties);

					if (spendTime < this.#parameters.duration) requestAnimation(() => animation.call(this));
					else if ('end' in this.#hooks) for (const callback of this.#hooks.end) callback('end');
				}
			}).call(this);
		}
	}

	stop() {
		this.#inProcess = false;
		if ('stop' in this.#hooks) for (const callback of this.#hooks.stop) callback('stop');
	}
}

export default AnimationService;