import axios from 'axios';
import { API_URI, WEATHER_WARNINGS, IS_SERVER } from 'src/constants';
import { socketON } from 'src/hooks/useSocket';
import Helper from 'src/front/helpers/Helper';
import { firebaseSW } from '.';
import globalConfig from 'src/utils/globalConfig';

export default new class {
	constructor() {
		if (typeof navigator !== 'undefined' && navigator?.serviceWorker?.addEventListener) {
		  navigator.serviceWorker.addEventListener('message', (ev) => {
		  	if (ev.data.type === 'firebaseEvent') {
		  		this.#runEvent(ev.data.data.type, ev.data.data.data);
		  	}
		  });
		}
	}

	#weatherWarningsData = {
		user: null, dataForUser: null, language: null, city: null, data: null, requests: {}, socketInitialized: false,
		initCallbacks: []
	};

	#events = {};

	setWeatherWarningsParams(user, language, city) {
		this.#weatherWarningsData.user = user;
		this.#weatherWarningsData.language = language;
		this.#weatherWarningsData.city = city;

		if (this.#weatherWarningsData.initCallbacks.length) {
			for (const callback of this.#weatherWarningsData.initCallbacks) callback();
			this.#weatherWarningsData.initCallbacks = [];
		}
	}

	on(event, callback) {
		if (!(event in this.#events)) this.#events[event] = [];
		if (!(this.#events[event].includes(callback))) this.#events[event].push(callback);
	}

	off(event, callback) {
		if (event in this.#events) {
			this.#events[event] = this.#events[event].filter((c) => c !== callback);
			if (!this.#events[event].length) delete this.#events[event];
		}
	}

	#runEvent(event, ...data) {
		if (event in this.#events) {
			for (const callback of this.#events[event]) callback(...Helper.cloneObject(data));
		}
	}

	#clearWeatherWarningsDataRequest(user, requestName) {
		if (user in this.#weatherWarningsData.requests) {
			if (requestName in this.#weatherWarningsData.requests[user]) delete this.#weatherWarningsData.requests[user][requestName];
			if (!Object.keys(this.#weatherWarningsData.requests[user]).length) delete this.#weatherWarningsData.requests[user];
		}
	}

	getCurrentUserWeatherWarnings() {
		return new Promise((resolve, reject) => {
			const requestName = 'getCurrentUserWeatherWarnings';
			const callback = () => {
				if (this.#weatherWarningsData.user) {
					if (this.#weatherWarningsData.data && this.#weatherWarningsData.dataForUser === this.#weatherWarningsData.user) {
						resolve(Helper.cloneObject(this.#weatherWarningsData.data));
					} else {
						const user = this.#weatherWarningsData.user;

						this.#weatherWarningsData.data = null;
						this.#weatherWarningsData.dataForUser = user;

						if (!this.#weatherWarningsData.socketInitialized) {
							this.#weatherWarningsData.socketInitialized = true;

					    socketON('weather warning subscription update', (id) => this.loadSubscriptionWeatherWarnings(id));

					    socketON('weather warning subscription toggle', ({ _id, active }) => {
					    	if (this.#weatherWarningsData.data) {
					    		for (const subscription of this.#weatherWarningsData.data.userSubscriptions) {
					    			if (subscription._id === _id && subscription.weatherWarning) {
					    				subscription.weatherWarning.active = active;
					    				this.#runEvent('weatherWarningsSubscriptionsUpdate', this.#weatherWarningsData.data);
					    				break;
					    			}
					    		}
					    	}
					    });

					    socketON('weather warning subscription delete', (id) => {
					    	if (this.#weatherWarningsData.data) {
					    		this.#weatherWarningsData.data.userSubscriptions = this.#weatherWarningsData.data.userSubscriptions.filter((s) => s._id !== id);
					    		this.#runEvent('weatherWarningsSubscriptionsUpdate', this.#weatherWarningsData.data);
					    	}
					    });
					  }

						if (!(user in this.#weatherWarningsData.requests)) this.#weatherWarningsData.requests[user] = {};
						if (!(requestName in this.#weatherWarningsData.requests[user])) {
							this.#weatherWarningsData.requests[user][requestName] = {
								request: axios
									.get(`${API_URI}/push-notifications/get-weather-warnings/${this.#weatherWarningsData.language}/${this.#weatherWarningsData.city}`, { withCredentials: true })
									.then(({ data }) => {
										if (user === this.#weatherWarningsData.user) this.#weatherWarningsData.data = data;

										if (!data.userSubscriptions.filter((s) => s.isCurrentClienSubscription).length) {
											firebaseSW.postMessage({
								        type: 'userWeatherSubscriptions',
								        data: {
								        	id: user,
								          subscriptions: {}
								        }
								      });
										}

										for (const { resolve } of this.#weatherWarningsData.requests[user][requestName].promises) resolve(Helper.cloneObject(data));

										this.#clearWeatherWarningsDataRequest(user, requestName);
									})
									.catch((error) => {
										for (const { reject } of this.#weatherWarningsData.requests[user][requestName].promises) reject(error);

										this.#clearWeatherWarningsDataRequest(user, requestName);
									}),
								promises: []
							};
						}
						this.#weatherWarningsData.requests[user][requestName].promises.push({ resolve, reject });
					}
				} else resolve(null);
			};

			if (this.#weatherWarningsData.language && this.#weatherWarningsData.city) callback();
			else this.#weatherWarningsData.initCallbacks.push(callback);
		});
	}

	loadSubscriptionWeatherWarnings(id) {
		return new Promise((resolve, reject) => {
			if (this.#weatherWarningsData.user && this.#weatherWarningsData.data) {
				const requestName = `loadSubscriptionWeatherWarnings_${id}`;
				const user = this.#weatherWarningsData.user;

				if (!(user in this.#weatherWarningsData.requests)) this.#weatherWarningsData.requests[user] = {};
				if (!(requestName in this.#weatherWarningsData.requests[user])) {
					this.#weatherWarningsData.requests[user][requestName] = {
						request: axios
							.get(`${API_URI}/push-notifications/get-subscription-weather-warnings/${this.#weatherWarningsData.language}/${id}`, { withCredentials: true })
							.then(({ data }) => {
								if (user === this.#weatherWarningsData.user && this.#weatherWarningsData.data) {
									if (!data.subscription.weatherWarning.subscriptions) data.subscription.weatherWarning.subscriptions = {};

									this.#weatherWarningsData.data.userSubscriptions = this.#weatherWarningsData.data.userSubscriptions.filter((s) => s._id !== data.subscription._id && (!data.subscription.isCurrentClienSubscription || !s.isCurrentClienSubscription));
									this.#weatherWarningsData.data.userSubscriptions.push(data.subscription);
									this.#weatherWarningsData.data.subscriptionsCities = { ...this.#weatherWarningsData.data.subscriptionsCities, ...data.subscriptionsCities };

									this.#weatherWarningsData.data.userSubscriptions.sort((a, b) => (new Date(a.createdAt)).getTime() - (new Date(b.createdAt)).getTime());

									this.#runEvent('weatherWarningsSubscriptionsUpdate', this.#weatherWarningsData.data);
								}

								for (const { resolve } of this.#weatherWarningsData.requests[user][requestName].promises) resolve();

								this.#clearWeatherWarningsDataRequest(user, requestName);
							})
							.catch((error) => {
								for (const { reject } of this.#weatherWarningsData.requests[user][requestName].promises) reject(error);

								this.#clearWeatherWarningsDataRequest(user, requestName);
							}),
						promises: []
					};
				}
				this.#weatherWarningsData.requests[user][requestName].promises.push({ resolve, reject });
			} else resolve();
		});
	}

	getLocationToNotification(id) {
		return axios
				.get(`${API_URI}/push-notifications/get-location-to-notification/${id}`, { withCredentials: true });
	}

	async toggleNotifications(id) {
		if (!id && await Notification.requestPermission() === 'denied') throw new Error('Messaging: The notification permission was not granted and blocked instead. (messaging/permission-blocked).');

		return axios
				.post(`${API_URI}/push-notifications/toggle-notifications`, { id }, { withCredentials: true });
	}

	deleteNotification(id) {
		return axios
        .delete(`${API_URI}/push-notifications/delete-notification-subscribtion/${id}`, { withCredentials: true });
	}

	async subscribeCurrentDeviceNotifications(citiesSubscriptions, subscriptionsCities, translates, warnings, apiKeys, lang, languages, user) {
		const { initializeApp } = await import('firebase/app');
    const { getMessaging, getToken } = await import('firebase/messaging');

    const app = initializeApp(apiKeys.client);
    const messaging = getMessaging(app);
    let token = await new Promise((resolve, reject) => {
    	const start = Date.now();

    	(async function tryToGetToken() {
    		try {
    			resolve(await getToken(messaging, { vapidKey: apiKeys.certificate }));
    		} catch (ex) {
    			if (Date.now() - start < 5000) setImmediate(tryToGetToken);
    			else reject(ex);
    		}
    	})();
    });

    if (token) {
      await this.clearSubscriptions(languages, user);

      await firebaseSW.postMessage({
        type: 'updateConfigs',
        data: {
          language: lang.currentLanguage._id,
          warnings,
          configs: {
            iconBaseUrl: Helper.getFileUrl('weather-icon', ''),
            apiUri: API_URI
          }
        }
      });

    	const { data } = await axios
    		.post(
    			`${API_URI}/push-notifications/subscribe-current-device-notifications`,
    			{
            token,
            citiesSubscriptions,
            language: { id: lang.currentLanguage._id, localization: lang.currentLocalizationIndex }
          },
          { withCredentials: true }
         );

    	if (data.success) {
        await firebaseSW.postMessage({
          type: 'userWeatherSubscriptions',
          data: {
            subscriptions: Object.keys(citiesSubscriptions).reduce((a, v) => {
              const codes = citiesSubscriptions[v].reduce((_a, _v) => {
                for (const warning of WEATHER_WARNINGS) {
                  if (warning.name === _v) {
                    _a.push(...Object.keys(warning.codes));
                    break;
                  }
                }
                return _a;
              }, []);

              if (codes.length) {
                a[v] = {
                  codes,
                  link: subscriptionsCities[v].link,
                  countryId: subscriptionsCities[v].country_id,
                  countryLink: subscriptionsCities[v].country_link,
                  tokens: {
                    '%%CITY_NAME_LOCATIVE%%': subscriptionsCities[v].name_locative,
                    '%%COUNTRY_NAME_LOCATIVE%%': subscriptionsCities[v].country_name_locative,
                  }
                };
              }

              return a;
            }, {}),
            id: user._id,
            sid: data.sid,
            buttons: [
              { action: 'city', title: translates['Weather in !city'] },
              { action: 'country', title: translates['Weather in !country'] }
            ],
            language: {
              id: lang.currentLanguage._id,
              shortName: lang.currentLocalization.shortName,
              baseUrl: `${(lang.currentLocalization.domain || globalConfig('defaultClientUri'))}${!lang.currentLocalization.domain && !lang.currentLocalization.default ? '/' + lang.currentLocalization.shortName : ''}`
            }
          }
        });

        await firebaseSW.postMessage({
          type: 'deleteInactiveSubscriptions',
          data: data.subscribedUsers
        });
      } else throw new Error('Something wrong');
    } else throw new Error(lang.t('No registration token available. Request permission to generate one.'));
	}

	clearSubscriptions(languages, user, allDomains = false) {
	  return new Promise((resolve, reject) => {
	    const currentDomain = `${window.location.protocol}//${window.location.host}`;
	    const domains = languages
	      .reduce((a, v) => (v.localizations.forEach((l) => l.domain && !a.includes(l.domain) && a.push(l.domain)), a), [globalConfig('defaultClientUri')])
	      .filter((d) => allDomains || d !== currentDomain);
	    let rejected = false;
	    let done = 0;

	    if (domains.length) {
	      function messageHandler(ev) {
	        if (domains.includes(ev.origin)) {
	          switch (ev.data) {
	            case 'unsubscribe-weather-warnings-iframe-init':
	              ev.source.postMessage(`unsubscribe-user:${user._id}`, ev.origin);
	              break;

	            case 'unsubscribe-weather-warnings-iframe-done':
	              if (!rejected && ++done === domains.length) {
	                clearTimeout(timeout);
	                clear();
	                resolve();
	              }
	              break;
	          }
	        }
	      }

	      window.addEventListener('message', messageHandler);

	      function clear() {
	        window.removeEventListener('message', messageHandler);

	        for (const iframe of iframes) {
	          iframe.parentNode.removeChild(iframe);
	        }
	      }
	      
	      const iframes = domains.map((d) => {
	        const iframe = document.createElement('iframe');
	        iframe.style.display = 'none';
	        iframe.src = `${d}/unsubscribe-weather-warnings`;
	        document.body.appendChild(iframe);
	        return iframe;
	      });

	      let timeout = setTimeout(() => {
	        rejected = true;
	        clear();
	        reject(new Error('Time is up'));
	      }, 5000);
	    } else resolve();
	  });
	}

	subscribeOtherDeviceNotifications(data) {
		return axios
		    .post(`${API_URI}/push-notifications/subscribe-other-device-notifications`, data, { withCredentials: true });
	}
};