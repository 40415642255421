import React, { useState, useEffect, useRef, useContext } from 'react';
import Link from 'src/front/components/Link';
import { API_URI, IS_SERVER } from 'src/constants';
import LoadingScreen from 'src/components/LoadingScreen';
import useLang from 'src/hooks/useLang';
import axios from 'axios';
import Popup from './Popup';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FrontContext } from '../helpers/FrontContext';
import Navigation from 'src/front/components/Navigation';
import Helper from 'src/front/helpers/Helper';
import SearchLocation from 'src/front/components/SearchLocation';
import { useHistory } from 'react-router';
import { Search } from 'src/front/components/icons';

const componentData = {};

function RightSidebarContent({ match: { params, url, path }, match }) {
  const lang = useLang('RightSidebar');
  const { myCities, sidebarLocations, setProperty, staticData } = useContext(FrontContext);
  const [firstRender, setFirstRender] = useState(true);

  const [subMenuOpened, setSubMenuOpened] = useState(false);
  const isMount = useRef(true);

  const handleOpenSubMenu = (ev) => {
    ev.preventDefault();

    if (!subMenuOpened) {
      const close = () => {
        if (isMount.current) setSubMenuOpened(false);
        document.removeEventListener('click', close);
      };

      setSubMenuOpened(true);
      document.addEventListener('click', close);
    }
  };

  useEffect(() => {
    setFirstRender(false);

    return () => {
      isMount.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoaded = IS_SERVER || (lang.loaded && sidebarLocations);
  const isSubmenuPage = ['/news/:country?', '/news/weather-in-:country-:date', '/advertising/', '/partnership/', '/feedback/', '/service_information/'].map((l) => IS_SERVER ? l : lang.l(l)).includes(path);

  return (
    <>
      <div className="right-sidebar-block" id="right-sidebar-menu">
        <ul>
          <li>
            {isLoaded ?
              (url === (IS_SERVER ? '/' : lang.l('/')) ?
              <span className="current text-4 color-10">{lang.t('Home')}</span> :
              <Link className="text-3 color-2" to={lang.l('/')}>{lang.t('Home')}</Link>) :
              <span className="content-preloader" style={{ width: '100%', height: '19px' }}></span>
            }
          </li>
          <li>
            {isLoaded ?
              (url === (IS_SERVER ? '/articles' : lang.l('/articles')) ?
              <span className="current text-4 color-10">{lang.t('Articles')}</span> :
              <Link className="text-3 color-2" to={lang.l('/articles')}>{lang.t('Articles')}</Link>) :
              <span className="content-preloader" style={{ width: '100%', height: '19px' }}></span>
            }
          </li>
          <li>
            {isLoaded ?
              (url === (IS_SERVER ? '/widgets/' : lang.l('/widgets/')) ?
              <span className="current text-4 color-10">{lang.t('Weather widgets')}</span> :
              <Link className="text-3 color-2" to={lang.l('/widgets/')}>{lang.t('Weather widgets')}</Link>) :
              <span className="content-preloader" style={{ width: '100%', height: '19px' }}></span>
            }
          </li>
          <li className={`with-submenu${subMenuOpened ? ' opened' : ''}${isSubmenuPage ? ' current' : ''}`}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className={`relative ${isSubmenuPage ? 'text-4 color-10' : 'text-3 color-2'}`} onClick={handleOpenSubMenu} href="#">{isLoaded ? lang.t('Information') : <span className="content-preloader" style={{ width: '100%', height: '19px' }}></span>}</a>
            {
              !IS_SERVER && !firstRender &&
              <ul>
                {
                  !!myCities.filter((c) => c?.constructor === Object).length && 
                  <li>
                    {
                      path === lang.l('/news/:country?') ?
                      <span>{isLoaded ? lang.t('News') : <span className="content-preloader" style={{ width: '100px', height: '19px' }}/>}</span> :
                      <Link to={lang.l(`/news${myCities[0].link.substring(0, 8) != '/israel/' ? '/' + myCities[0].link.split('/')[1] : ''}`)}>{isLoaded ? lang.t('News') : <span className="content-preloader" style={{ width: '100px', height: '19px' }}></span>}</Link>
                    }
                  </li>
                }
                <li>
                  {
                    path === lang.l('/advertising/') ?
                    <span>{isLoaded ? lang.t('Advertising on the site') : <span className="content-preloader" style={{ width: '100px', height: '19px' }}/>}</span> :
                    <Link to={lang.l('/advertising/')}>{isLoaded ? lang.t('Advertising on the site') : <span className="content-preloader" style={{ width: '100px', height: '19px' }}></span>}</Link>
                  }
                </li>
                <li>
                  {
                    path === lang.l('/partnership/') ?
                    <span>{isLoaded ? lang.t('Cooperation') : <span className="content-preloader" style={{ width: '100px', height: '19px' }}/>}</span> :
                    <Link to={lang.l('/partnership/')}>{isLoaded ? lang.t('Cooperation') : <span className="content-preloader" style={{ width: '100px', height: '19px' }}></span>}</Link>
                  }
                </li>
                <li>
                  <Link to='#'>{isLoaded ? lang.t('Buttons and banners') : <span className="content-preloader" style={{ width: '100px', height: '19px' }}></span>}</Link>
                </li>
                <li>
                  {
                    path === lang.l('/service_information/') ?
                    <span>{isLoaded ? lang.t('Service information') : <span className="content-preloader" style={{ width: '100px', height: '19px' }}/>}</span> :
                    <Link to={lang.l('/service_information/')}>{isLoaded ? lang.t('Service information') : <span className="content-preloader" style={{ width: '100px', height: '19px' }}></span>}</Link>
                  }
                </li>
                <li>
                  {
                    path === lang.l('/feedback/') ?
                    <span>{isLoaded ? lang.t('Feedback') : <span className="content-preloader" style={{ width: '100px', height: '19px' }}/>}</span> :
                    <Link to={lang.l('/feedback/')}>{isLoaded ? lang.t('Feedback') : <span className="content-preloader" style={{ width: '100px', height: '19px' }}></span>}</Link>
                  }
                </li>
              </ul>
            }
          </li>
        </ul>
      </div>
      {
        (IS_SERVER || !sidebarLocations?.country || sidebarLocations.country === 'israel') &&
        <>
          {IS_SERVER && "{if (!sidebarLocations || !sidebarLocations.country || sidebarLocations.country === 'israel') :}"}
          <Beaches lang={lang} isLoaded={isLoaded} url={url}/>
          <Cities
            lang={lang}
            isLoaded={isLoaded}
            url={url}
          />
          {IS_SERVER && '{endif}'}
        </>
      }
      {
        (IS_SERVER || (sidebarLocations?.country && sidebarLocations.country !== 'israel')) &&
        <>
          {IS_SERVER && "{if (sidebarLocations && sidebarLocations.country && sidebarLocations.country !== 'israel') :}"}
          <Cities
            lang={lang}
            isLoaded={isLoaded}
            url={url}
          />
          <Beaches lang={lang} isLoaded={isLoaded} url={url}/>
          {IS_SERVER && '{endif}'}
        </>
      }
      <Switch>
        <Route path={lang.l('/account-management')} render={() => ''}/>
        <Route
          path="*"
          render={() => <>
            <div className="banner-wrapper" id="banner-160x600">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="advertising-link" href="#">{isLoaded ? lang.t('Advertising on the site') : <span className="content-preloader" style={{ width: '105px', height: '17px' }}></span>}</a>
              <img width="160" height="600" style={{display: 'block'}} alt="Banner" src="/images/banner_160x600.jpg" loading="lazy"/>
            </div>
            <Link to={lang.l('/widgets/')} className="widgets-link">{isLoaded ? lang.t('Weather widgets') : <span className="content-preloader" style={{ width: '100%', height: '19px' }}></span>}</Link>
          </>}
        />
      </Switch>
    </>
  );
}

function Beaches({ lang, isLoaded, url }) {
  return (
    <div className="beaches right-sidebar-block" id="sidebar-beaches">
      <h2>{isLoaded ? lang.t('Beaches') : <span className="content-preloader" style={{ width: '100%', height: '26px' }}></span>}</h2>
      <ul>
        <li>
          {
            IS_SERVER ?
            <>
              {`{if (${lang.l('/beaches/beaches_on_the_mediterranean_sea').replace(/^\{print |\}$/g, '')} == requestUrl) :}`}
              <span className="current text-4 color-10">{lang.t('Mediterranean Sea')}</span>
              {'{else:}'}
              <Link className="text-3 color-2" to={lang.l('/beaches/beaches_on_the_mediterranean_sea')}>{lang.t('Mediterranean Sea')}</Link>
              {'{endif}'}
            </>
            : (
              isLoaded ?
              (url === lang.l('/beaches/beaches_on_the_mediterranean_sea') ?
              <span className="current text-4 color-10">{lang.t('Mediterranean Sea')}</span> :
              <Link className="text-3 color-2" to={lang.l('/beaches/beaches_on_the_mediterranean_sea')}>{lang.t('Mediterranean Sea')}</Link>) :
              <span className="content-preloader" style={{ width: '100%', height: '19px' }}></span>
            )
          }
        </li>
        <li>
          {
            IS_SERVER ?
            <>
              {`{if (${lang.l('/beaches/beaches_on_the_red_sea').replace(/^\{print |\}$/g, '')} == requestUrl) :}`}
              <span className="current text-4 color-10">{lang.t('Red Sea')}</span>
              {'{else:}'}
              <Link className="text-3 color-2" to={lang.l('/beaches/beaches_on_the_red_sea')}>{lang.t('Red Sea')}</Link>
              {'{endif}'}
            </>
            : (
              isLoaded ?
              (url === lang.l('/beaches/beaches_on_the_red_sea') ?
              <span className="current text-4 color-10">{lang.t('Red Sea')}</span> :
              <Link className="text-3 color-2" to={lang.l('/beaches/beaches_on_the_red_sea')}>{lang.t('Red Sea')}</Link>) :
              <span className="content-preloader" style={{ width: '100%', height: '19px' }}></span>
            )
          }
        </li>
        <li>
          {
            IS_SERVER ?
            <>
              {`{if (${lang.l('/beaches/beaches_on_the_kinneret').replace(/^\{print |\}$/g, '')} == requestUrl) :}`}
              <span className="current text-4 color-10">{lang.t('Kinneret')}</span>
              {'{else:}'}
              <Link className="text-3 color-2" to={lang.l('/beaches/beaches_on_the_kinneret')}>{lang.t('Kinneret')}</Link>
              {'{endif}'}
            </>
            : (
              isLoaded ?
              (url === lang.l('/beaches/beaches_on_the_kinneret') ?
              <span className="current text-4 color-10">{lang.t('Kinneret')}</span> :
              <Link className="text-3 color-2" to={lang.l('/beaches/beaches_on_the_kinneret')}>{lang.t('Kinneret')}</Link>) :
              <span className="content-preloader" style={{ width: '100%', height: '19px' }}></span>
            )
          }
        </li>
        <li>
          <span className="disabled">{isLoaded ? lang.t('The Dead Sea') : <span className="content-preloader" style={{ width: '100%', height: '19px' }}></span>}</span>
        </li>
      </ul>
    </div>
  );
}

function Cities({ lang, isLoaded, url }) {
  const { sidebarLocations, setProperty } = useContext(FrontContext);
  const [popupOpened, setPopupOpened] = useState(false);
  const [keyboardState, setKeyboardState] = useState({ section: 'regions' });
  const history = useHistory();
  const sidebarLocationsRef = useRef(null);
  const keyboardStateRef = useRef();
  const popupRef = useRef(null);
  const popupOpenedRef = useRef(null);
  const isLoading = useRef(false);

  sidebarLocationsRef.current = sidebarLocations;
  keyboardStateRef.current = keyboardState;

  const scrollCitiesToTop = () => popupRef.current.current.querySelector(':scope > .cities > ul').scroll({ top: 0, left: 0, behavior: 'smooth' });

  const handleOpenPopup = () => {
    if (!sidebarLocations.regions) {
      if ('popup' in componentData && sidebarLocations.country in componentData.popup) {
        setProperty('sidebarLocations', { ...sidebarLocations, ...componentData.popup[sidebarLocations.country] });
      } else {
        if (!('sentPopupRequests' in componentData)) componentData.sentPopupRequests = {};
        if (!(sidebarLocations.country in componentData.sentPopupRequests)) componentData.sentPopupRequests[sidebarLocations.country] = { requestNumber: 0 };
        if (!('timeout' in componentData.sentPopupRequests[sidebarLocations.country])) {
          const requestNumber = ++componentData.sentPopupRequests[sidebarLocations.country].requestNumber;

          componentData.sentPopupRequests[sidebarLocations.country].timeout = setTimeout(() => {
            if ('timeout' in componentData.sentPopupRequests[sidebarLocations.country]) delete componentData.sentPopupRequests[sidebarLocations.country].timeout;
          }, 5000);

          axios.get(`${API_URI}/sidebar-init-cities-popup/${sidebarLocations.country}/${lang.currentLanguage._id}`)
          .then(({ data }) => {
            if (!sidebarLocationsRef.current.regions) {
              const initData = {
                ...data,
                region: '0',
                page: 1,
                loadedPage: 1,
                cities: {
                  '0': {
                    1: data.cities
                  }
                }
              };

              if (!('popup' in componentData)) componentData.popup = {};
              componentData.popup[sidebarLocations.country] = initData;

              setProperty('sidebarLocations', { ...sidebarLocations, ...initData });
            }
          })
          .catch(() => {
            if ('timeout' in componentData.sentPopupRequests[sidebarLocations.country] && componentData.sentPopupRequests[sidebarLocations.country].requestNumber === requestNumber) {
              clearTimeout(componentData.sentPopupRequests[sidebarLocations.country].timeout);
              delete componentData.sentPopupRequests[sidebarLocations.country].timeout;
            }
          });
        }
      }
    } else setProperty('sidebarLocations', { ...sidebarLocations, region: '0' });

    setPopupOpened(true);
  };

  const handleClosePopup = () => setPopupOpened(false);

  const handleSetRegion = (region) => {
    if (isLoading.current) return false;

    const page = 1;

    if (keyboardStateRef.current.section === 'cities' && keyboardStateRef.current.index !== 0) setKeyboardState({ section: 'cities', index: 0 });

    if (region in sidebarLocationsRef.current.cities) {
      setProperty('sidebarLocations', { ...sidebarLocationsRef.current, region, page, loadedPage: page });
      return Promise.resolve();
    } else {
      setProperty('sidebarLocations', { ...sidebarLocationsRef.current, region, page, loadedPage: null });
      return getPopupLocations({ region, page });
    }
  };

  const handleSetPage = (page) => {
    if (isLoading.current) return false;

    if (page in sidebarLocationsRef.current.cities[sidebarLocationsRef.current.region]) {
      setProperty('sidebarLocations', { ...sidebarLocationsRef.current, page, loadedPage: page });
      return Promise.resolve();
    } else {
      setProperty('sidebarLocations', { ...sidebarLocationsRef.current, page });
      return getPopupLocations({ region: sidebarLocationsRef.current.region, page });
    }
  };

  const getPopupLocations = ({ region, page }) => {
    const country = sidebarLocationsRef.current.country;

    isLoading.current = true;

    return axios
      .get(`${API_URI}/sidebar-region-cities/${sidebarLocationsRef.current.country}/${region}/${page}/${lang.currentLanguage._id}`, { withCredentials: true })
      .then(({ data }) => {
        isLoading.current = false;

        if (sidebarLocationsRef.current.country === country) {
          if (sidebarLocationsRef.current.region === region && sidebarLocationsRef.current.page === page) {
            setProperty('sidebarLocations', {
              ...sidebarLocationsRef.current,
              region,
              page,
              loadedPage: page,
              cities: {
                ...sidebarLocationsRef.current.cities,
                [region]: { ...region in sidebarLocationsRef.current.cities ? sidebarLocationsRef.current.cities[region] : {}, [page]: data }
              }
            });
          } else if (!(region in sidebarLocationsRef.current.cities) || !(page in sidebarLocationsRef.current.cities[region])) {
            setProperty('sidebarLocations', {
              ...sidebarLocationsRef.current,
              cities: {
                ...sidebarLocationsRef.current.cities,
                [region]: { ...region in sidebarLocationsRef.current.cities ? sidebarLocationsRef.current.cities[region] : {}, [page]: data }
              }
            });
          }
        }
      }).catch(() => isLoading.current = false);
  };

  const getCitiesCount = (count, page, perPage) => Math.min(count - (page - 1) * perPage, perPage);

  const generateNavigationProps = () => {
    let pager = [];
    let page = 1;
    let disabled = true;

    if (sidebarLocations) {
      if ('region' in sidebarLocations && sidebarLocations.region in sidebarLocations.cities) {
        if (sidebarLocations.page === sidebarLocations.loadedPage) {
          pager = sidebarLocations.cities[sidebarLocations.region][sidebarLocations.page].pager;
          disabled = false;
          page = sidebarLocations.page;
        } else {
          pager = sidebarLocations.cities[sidebarLocations.region][sidebarLocations.loadedPage].pager;
          page = sidebarLocations.loadedPage;
        }
      } else {
        const countPages = Math.min(Math.ceil(
          (
            !('region' in sidebarLocations) || sidebarLocations.region === '0' ?
            sidebarLocations.totalCities :
            sidebarLocations.regions.filter((r) => r._id === sidebarLocations.region)[0].cities_quantity
          ) / sidebarLocations.citiesPerPage), 5);

        if (countPages > 1) {
          pager.push({ page: 0, title: '<' });
          for (let i = 1; i <= countPages; i++) pager.push({ page: i, title: i });
          pager.push({ page: 2, title: '>' });
        }
      }
    }

    const setPage = (p) => {
      if (keyboardStateRef.current.section === 'cities' && keyboardStateRef.current.index !== 0) setKeyboardState({ section: 'cities', index: 0 });
      handleSetPage(p).then(scrollCitiesToTop);
    };

    return { pager, page, disabled, setPage };
  };

  useEffect(() => {
    const canUpdate = (() => {
      let timeout = null;

      return () => {
        if (timeout) return false;
        else {
          timeout = setTimeout(() => timeout = null, 200);
          return true;
        }
      };
    })();

    const handleKeyDown = (ev) => {
      switch (keyboardStateRef.current.section) {
        case 'regions':
          if ('regions' in sidebarLocationsRef.current && !isLoading.current && (document.activeElement?.tagName?.toLowerCase() !== 'input' || document.activeElement?.type !== 'text')) {
            let region = null;
            let nodeIndex = null;

            switch (ev.keyCode) {
              case 40: {
                ev.preventDefault();

                if (sidebarLocationsRef.current.region === '0' && sidebarLocationsRef.current.regions.length) {
                  region = sidebarLocationsRef.current.regions[0]._id;
                  nodeIndex = 1;
                } else {
                  for (let i = 0; i < sidebarLocationsRef.current.regions.length; i++) {
                    if (sidebarLocationsRef.current.regions[i]._id === sidebarLocationsRef.current.region && sidebarLocationsRef.current.regions.length > i + 1) {
                      region = sidebarLocationsRef.current.regions[i + 1]._id;
                      nodeIndex = i + 2;
                      break;
                    }
                  }
                }
                break;
              }

              case 38: {
                ev.preventDefault();

                if (sidebarLocationsRef.current.region !== '0') {
                  for (let i = 0; i < sidebarLocationsRef.current.regions.length; i++) {
                    if (sidebarLocationsRef.current.regions[i]._id === sidebarLocationsRef.current.region) {
                      region = i ? sidebarLocationsRef.current.regions[i - 1]._id : '0';
                      nodeIndex = i;
                      break;
                    }
                  }
                }
                break;
              }

              case 39: case 13: {
                ev.preventDefault();

                if (
                  sidebarLocationsRef.current.region in sidebarLocationsRef.current.cities && sidebarLocationsRef.current.page in sidebarLocationsRef.current.cities[sidebarLocationsRef.current.region] &&
                  sidebarLocationsRef.current.cities[sidebarLocationsRef.current.region][sidebarLocationsRef.current.page]?.cities?.length
                ) {
                  setKeyboardState({ section: 'cities', index: 0 });
                }
                break;
              }
            }

            if (region !== null && canUpdate()) {
              handleSetRegion(region);
              popupRef.current.current.querySelectorAll(':scope > .regions > ul > li')[nodeIndex].scrollIntoView({
                block: 'nearest',
                behavior: 'smooth'
              });
            }
          }
          break;

        case 'cities':
          if (
            'cities' in sidebarLocationsRef.current && sidebarLocationsRef.current.region in sidebarLocationsRef.current.cities && sidebarLocationsRef.current.page in sidebarLocationsRef.current.cities[sidebarLocationsRef.current.region] &&
            (document.activeElement?.tagName?.toLowerCase() !== 'input' || document.activeElement?.type !== 'text')
          ) {
            const { cities, pager } = sidebarLocationsRef.current.cities[sidebarLocationsRef.current.region][sidebarLocationsRef.current.page];
            let nodeIndex = null;

            switch (ev.keyCode) {
              case 37:
                if (keyboardStateRef.current.index) nodeIndex = keyboardStateRef.current.index - 1;
                else if (pager[0].page) {
                  handleSetPage(pager[0].page).then(() => {
                    const nodes = popupRef.current.current.querySelectorAll(':scope > .cities > ul > li');

                    nodes[nodes.length - 1].scrollIntoView({
                      block: 'nearest',
                      behavior: 'smooth'
                    });
                    setKeyboardState({ ...keyboardStateRef.current, index: nodes.length - 1 });
                  });
                } else setKeyboardState({ section: 'regions' });
                break;

              case 39: {
                if (keyboardStateRef.current.index + 1 < cities.length) nodeIndex = keyboardStateRef.current.index + 1;
                else if (pager && pager[pager.length - 1].page) {
                  handleSetPage(pager[pager.length - 1].page);
                  nodeIndex = 0;
                }
                break;
              }

              case 40:
                ev.preventDefault();
                if (keyboardStateRef.current.index + 3 < cities.length) nodeIndex = keyboardStateRef.current.index + 3;
                break;

              case 38:
                ev.preventDefault();
                if (keyboardStateRef.current.index - 3 >= 0) nodeIndex = keyboardStateRef.current.index - 3;
                break;

              case 13:
                history.push(lang.l(`/${sidebarLocationsRef.current.country}/${cities[keyboardStateRef.current.index].link}`));
                break;
            }

            if (nodeIndex !== null) {
              setKeyboardState({ ...keyboardStateRef.current, index: nodeIndex });
              popupRef.current.current.querySelectorAll(':scope > .cities > ul > li')[nodeIndex].scrollIntoView({
                block: 'nearest',
                behavior: 'smooth'
              });
            }
          }
          break;
      }
    };

    if (popupOpened) window.addEventListener('keydown', handleKeyDown);

    return () => {
      if (popupOpened) window.removeEventListener('keydown', handleKeyDown);
    };
  }, [popupOpened]);

  return (
    <>
      <div className="cities right-sidebar-block" id="sidebar-cities">
        <h2>{isLoaded ? lang.t('Cities') : <span className="content-preloader" style={{ width: '100%', height: '26px' }}></span>}</h2>
        <ul>
          {
            IS_SERVER ?
            `
              {literal}
                {for (let i = 0; i < sidebarLocations.sidebarCities.length; i++) :}
                  <li>
                    {if (${lang.l("{print '/' + sidebarLocations.country + '/' + sidebarLocations.sidebarCities[i].link}").replace(/^\{print |\}$/g, '')} == requestUrl) :}
                      <span class="current">{print Helper.getFieldValue(sidebarLocations.sidebarCities[i].name, lang)}</span>
                    {else :}
                      <a href="${lang.l("{print '/' + sidebarLocations.country + '/' + sidebarLocations.sidebarCities[i].link}")}">{print Helper.getFieldValue(sidebarLocations.sidebarCities[i].name, lang)}</a>
                    {endif}
                  </li>
                {endfor}
              {/literal}
            `.replace(/\s{2,}/g, '')
            :
            (isLoaded ? sidebarLocations.sidebarCities.map((city) => {
              return (
                <li key={city._id}>
                  {
                    url === lang.l(`/${sidebarLocations.country}/${city.link}`) ?
                    <span className="current text-4 color-10">{Helper.getFieldValue(city.name, lang.currentLanguage._id)}</span> :
                    <Link className="text-3 color-2" to={lang.l(`/${sidebarLocations.country}/${city.link}`)}>{Helper.getFieldValue(city.name, lang.currentLanguage._id)}</Link>
                  }
                </li>
              );
            }) : [null, null, null, null, null].map((c) => <li>
              <span className="content-preloader" style={{ width: '100%', height: '26px' }}></span>
            </li>))
          }
        </ul>
        <div className="show-country-cities">
          {
            isLoaded ?
            <button
              className="text-3 color-2"
              onClick={handleOpenPopup}
            >
              {lang.t('All cities')}
            </button> :
            <span className="content-preloader" style={{ width: '100%', height: '21px' }}></span>
          }
        </div>
      </div>
      <Popup
        className="country-cities-popup flex"
        anchor="viewport"
        isOpened={popupOpened}
        onClose={handleClosePopup}
        anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
        popupOrigin={{ horizontal: 'center', vertical: 'center' }}
        popupRef={popupRef}
      >
        {
          sidebarLocations?.flag &&
          <div className="flag absolute">
          <img alt={sidebarLocations.country || 'flag'} src={Helper.getFileUrl('country', sidebarLocations.flag)}/>
          </div>
        }
        <div className="regions scroll">
          <ul>
            <li {...sidebarLocations?.region === '0' && { className: 'current' }}>
              <div>
                {
                  sidebarLocations?.region ?
                  <button
                    className={`text-4 ${sidebarLocations.region === '0' ? 'color-7' : 'color-1'}`}
                    onClick={() => handleSetRegion('0')}
                  >
                    {lang.t('All cities')}
                  </button> :
                  <span className="content-preloader" style={{ width: '250px', height: '38px' }}></span>
                }
              </div>
            </li>
            {
              (sidebarLocations?.regions || Array(sidebarLocations?.totalRegions || 0).fill()).map((region) => (
                <li {...region && sidebarLocations.region === region._id && { className: 'current' }}>
                  <div>
                    {
                      region ?
                      <button
                        className={`text-4 ${sidebarLocations.region === region._id ? 'color-7' : 'color-1'}`}
                        onClick={() => handleSetRegion(region._id)}
                      >
                        {Helper.getFieldValue(region.name, lang.currentLanguage._id)}
                      </button> :
                      <span className="content-preloader" style={{ width: '250px', height: '38px' }}></span>
                    }
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
        <div className="cities relative flex">
          <h3>
            {
              sidebarLocations?.countryNameGenitive ?
              lang.t('All cities of !location', { '!location': sidebarLocations.region === '0' ? sidebarLocations.countryNameGenitive : sidebarLocations.regions.filter((r) => r._id === sidebarLocations.region)[0].name_genitive }):
              <span className="content-preloader block" style={{ width: '200px', height: '22.45px', display: 'block' }}></span>
            }
          </h3>
          <div className="relative">
            <SearchLocation
              types={'countries,regions,cities'}
              clearButton={false}
              usePortal={true}
              selectLocation={(l) => history.push(lang.l(l.link))}
            />
            <Search color="#dce1ec"/>
          </div>
          {
            <>
              <ul className="scroll">
                {
                  (
                    sidebarLocations?.cities && sidebarLocations.region in sidebarLocations.cities && sidebarLocations.page in sidebarLocations.cities[sidebarLocations.region] ?
                    sidebarLocations.cities[sidebarLocations.region][sidebarLocations.page].cities :
                    Array(sidebarLocations ? getCitiesCount(!('region' in sidebarLocations) || sidebarLocations.region === '0' ? sidebarLocations.totalCities : +sidebarLocations.regions.filter((r) => r._id === sidebarLocations.region)[0].cities_quantity, sidebarLocations.page || 1, sidebarLocations.citiesPerPage) : 0).fill()
                  ).map((city, i) => (
                    <li className={`inline-block va-middle relative${city && keyboardState.section === 'cities' && i === keyboardState.index ? ' current' : ''}`}>
                      {
                        city ?
                        <Link title={city.name} className={`text-3 ${keyboardState.section === 'cities' && i === keyboardState.index ? 'color-7' : 'color-2'}`} to={lang.l(`/${sidebarLocations.country}/${city.link}`)}>{city.name}</Link> :
                        <span className="content-preloader" style={{ width: '100%', height: '19.19px' }}></span>
                      }
                    </li>
                  ))
                }
              </ul>
              <Navigation {...generateNavigationProps()}/>
            </>
          }
        </div>
      </Popup>
    </>
  );
}

export default RightSidebarContent;