export default {
	bezierCubic: function (t, p1, p2, p3, p4) {
    return Math.pow(1 - t, 3) * p1 + 3 * Math.pow(1 - t, 2) * t * p2 + 3 * (1 - t) * Math.pow(t, 2) * p3 +  Math.pow(t, 3) * p4;
  },
  solveQuadratic: function (a, b, c) {
    if (a === 0) return c / b;

    let discriminant = b * b - 4 * a * c;
    let roots = [];

    if (discriminant > 0) {
        let root1 = (-b + Math.sqrt(discriminant)) / (2 * a);
        let root2 = (-b - Math.sqrt(discriminant)) / (2 * a);
        roots.push(root1, root2);
    } else if (discriminant === 0) {
        let root = -b / (2 * a);
        roots.push(root);
    }

    return roots;
  },
  solveCubic: function (a, b, c, d) {
    if (a === 0) return this.solveQuadratic(b, c, d);

    let p = (3 * a * c - b * b) / (3 * a * a);
    let q = (2 * b * b * b - 9 * a * b * c + 27 * a * a * d) / (27 * a * a * a);
    let delta = (q * q / 4) + (p * p * p / 27);
    
    let roots = [];

    if (delta > 0) {
        let u = Math.cbrt(-q / 2 + Math.sqrt(delta));
        let v = Math.cbrt(-q / 2 - Math.sqrt(delta));
        roots.push(u + v - b / (3 * a));
    } else if (delta === 0) {
        let u = Math.cbrt(-q / 2);
        roots.push(2 * u - b / (3 * a));
        roots.push(-u - b / (3 * a));
    } else {
        let r = Math.sqrt(-p / 3);
        let phi = Math.acos(-q / (2 * r * r * r));
        for (let k = 0; k < 3; k++) {
            let angle = (phi + 2 * Math.PI * k) / 3;
            roots.push(2 * r * Math.cos(angle) - b / (3 * a));
        }
    }

    return roots;
  }
};