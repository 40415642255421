import React, { useState, useEffect, useRef, useContext } from 'react';
import ResizeSensible from './ResizeSensible';
import { FrontContext } from '../helpers/FrontContext';
import $ from 'jquery';

const screenOffset = 20;

function Sticky({ children, name, bottomStop = 'footer', bottomHook, media, exportResizeCallback, topFixedElement }) {
  const styleRef = useRef([{}, { position: 'relative' }]);
  const scrollTopRef = useRef(0);
  const elementRef = useRef(null);
  const callbackRef = useRef(null);
  const { stickyInstance } = useContext(FrontContext);
  const lock = useRef(false);
  const bottomHookRef = useRef(null);

  bottomHookRef.current = bottomHook;

  const setStyle = (s) => {
    const div = elementRef.current.querySelector(':scope > div');

    elementRef.current.removeAttribute('style');
    div.removeAttribute('style');

    for (const k in s[0]) elementRef.current.style[k] = s[0][k];
    for (const k in s[1]) div.style[k] = s[1][k];

    styleRef.current = s;
  };

  useEffect(() => {
    callbackRef.current = ({ forceScrollToBottom = null, ignoreLock = false } = {}) => {
      if (lock.current === false || ignoreLock) {
        let mediaSatisfied = !media;

        if (media) {
          // eslint-disable-next-line no-unused-vars
          for (const m of media.split(',')) {
            const [min, max] = m.split('-');

            if ((min === '*' || window.innerWidth >= min) && (max === '*' || window.innerWidth <= max)) {
              mediaSatisfied = true;
              break;
            }
          }
        }

        if (!mediaSatisfied) {
          setStyle([{}, { position: 'relative' }]);
          return;
        }

        const element = $(elementRef.current);
        const bottomStopElement = $(bottomStop);
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const maxBottom = bottomStopElement.length ? bottomStopElement.offset().top - parseFloat(bottomStopElement.css('margin-top')) : document.documentElement.scrollHeight - screenOffset;
        const scrollToBottom = forceScrollToBottom !== null ? forceScrollToBottom : scrollTop >= scrollTopRef.current;
        const height = element.find('> div').height();
        const elementOffsetTop = element.offset().top;
        const bottomPosition = elementOffsetTop + height;
        const screenBottom = scrollTop + window.innerHeight - screenOffset;
        const screenTop = scrollTop + screenOffset;
        let bottomHookValue = bottomPosition;
        let topOffset = 0;

        if (topFixedElement && $(topFixedElement).length) {
          const boundigRect = $(topFixedElement)[0].getBoundingClientRect();

          topOffset = boundigRect.top + boundigRect.height;
        }

        if (bottomHookRef.current) {
          const element = $(bottomHookRef.current);

          if (element.length && element[0].offsetParent) {
            let parent = element[0];
            let offset = 0;
    
            while (parent && parent.getAttribute('class') !== 'sticky') {
              offset += parent.offsetTop;
              parent = parent.offsetParent;
            }
    
            bottomHookValue = elementOffsetTop + offset + element.height() +
              parseFloat(element.css('padding-top')) + parseFloat(element.css('padding-bottom')) +
              parseFloat(element.css('border-top-width')) + parseFloat(element.css('border-bottom-width'));
          }
        }

        if (window.innerHeight >= height + bottomHookValue - bottomPosition + screenOffset * 2) {
          switch (styleRef.current[1].position) {
            case 'fixed': {
              if (screenTop + topOffset <= elementOffsetTop) {
                setStyle([{}, { position: 'relative' }]);
              } else if (screenTop + topOffset + height >= maxBottom) {
                setStyle([{ height: `${height}px` }, { position: 'absolute', top: `${maxBottom - bottomPosition}px`, width: `${element.width()}px` }]);
              } else if (!('top' in styleRef.current[1])) {
                setStyle([{ height: `${height}px` }, { position: 'fixed', top: `${screenOffset + topOffset}px`, width: `${element.width()}px` }]);
              }
              break;
            }

            case 'absolute': {
              if (screenTop + topOffset <= elementOffsetTop) {
                setStyle([{}, { position: 'relative' }]);
              } else if (screenTop + topOffset + height < maxBottom) {
                setStyle([{ height: `${height}px` }, { position: 'fixed', top: `${screenOffset + topOffset}px`, width: `${element.width()}px` }]);
              } else if (`${maxBottom - bottomPosition}px` !== styleRef.current[1].top) {
                setStyle([{ height: `${height}px` }, { position: 'absolute', top: `${maxBottom - bottomPosition}px`, width: `${element.width()}px` }]);
              }
              break;
            }

            default: {
              if (maxBottom > bottomPosition && screenTop + topOffset > elementOffsetTop) {
                if (screenBottom >= maxBottom - bottomPosition + bottomHookValue) {
                  setStyle([{ height: `${height}px` }, { position: 'absolute', top: `${maxBottom - bottomPosition}px`, width: `${element.width()}px` }]);
                } else {
                  setStyle([{ height: `${height}px` }, { position: 'fixed', top: `${screenOffset + topOffset}px`, width: `${element.width()}px` }]);
                }
              }
            }
          }
        } else {
          switch (styleRef.current[1].position) {
            case 'fixed': {
              if ('top' in styleRef.current[1]) {
                if (scrollToBottom) {
                  if (screenTop + topOffset <= elementOffsetTop || bottomPosition > maxBottom) {
                    setStyle([{}, { position: 'relative' }]);
                  } else if (screenBottom > maxBottom || scrollTopRef.current + topOffset + screenOffset + height > maxBottom) {
                    setStyle([{ height: `${height}px` }, { position: 'absolute', top: `${maxBottom - bottomPosition}px`, width: `${element.width()}px` }]);
                  } else if (screenBottom > scrollTopRef.current + topOffset + screenOffset + bottomHookValue - elementOffsetTop) {
                    setStyle([{ height: `${height}px` }, { position: 'fixed', bottom: `${screenOffset - bottomPosition + bottomHookValue}px`, width: `${element.width()}px` }]);
                  } else {
                    setStyle([{ height: `${height}px` }, { position: 'absolute', top: `${scrollTopRef.current + topOffset + screenOffset - elementOffsetTop}px`, width: `${element.width()}px` }]);
                  }
                } else {
                  if (screenTop + topOffset <= elementOffsetTop) {
                    setStyle([{}, { position: 'relative' }]);
                  }
                }
              } else {
                // const elementTop = scrollTopRef.current + window.innerHeight - screenOffset - height - bottomHookValue + bottomPosition;
                const elementTop = screenBottom - height - bottomHookValue + bottomPosition;

                if (scrollToBottom) {
                  if (elementTop <= elementOffsetTop) {
                    setStyle([{}, { position: 'relative' }]);
                  } else if (screenBottom + bottomPosition - bottomHookValue > maxBottom) {
                    setStyle([{ height: `${height}px` }, { position: 'absolute', top: `${maxBottom - bottomPosition}px`, width: `${element.width()}px` }]);
                  } else if (styleRef.current[1].position !== 'fixed' || styleRef.current[1].bottom !== `${screenOffset - bottomPosition + bottomHookValue}px`) {
                    setStyle([{ height: `${height}px` }, { position: 'fixed', bottom: `${screenOffset - bottomPosition + bottomHookValue}px`, width: `${element.width()}px` }]);
                  }
                } else {
                  if (screenTop + topOffset <= elementOffsetTop) {
                    setStyle([{}, { position: 'relative' }]);
                  } else if (screenTop + topOffset < elementTop) {
                    setStyle([{ height: `${height}px` }, { position: 'fixed', top: `${screenOffset + topOffset}px`, width: `${element.width()}px` }]);
                  } else {
                    setStyle([{ height: `${height}px` }, { position: 'absolute', top: `${elementTop - elementOffsetTop}px`, width: `${element.width()}px` }]);
                  }
                }
              }
              break;
            }
    
            case 'absolute': {
              const innerElementOffsetTop = element.find('> div').offset().top;
    
              if (scrollToBottom) {
                if (elementOffsetTop + height >= maxBottom || innerElementOffsetTop <= elementOffsetTop) {
                  setStyle([{}, { position: 'relative' }]);
                } else if (screenBottom >= maxBottom - bottomPosition + bottomHookValue || innerElementOffsetTop + height >= maxBottom) {
                  setStyle([{ height: `${height}px` }, { position: 'absolute', top: `${maxBottom - bottomPosition}px`, width: `${element.width()}px` }]);
                } else if (screenBottom > innerElementOffsetTop + height + bottomHookValue - bottomPosition && screenBottom + bottomPosition - bottomHookValue < maxBottom) {
                  setStyle([{ height: `${height}px` }, { position: 'fixed', bottom: `${screenOffset - bottomPosition + bottomHookValue}px`, width: `${element.width()}px` }]);
                }
              } else {
                if (screenTop + topOffset <= elementOffsetTop || innerElementOffsetTop <= elementOffsetTop) {
                  setStyle([{}, { position: 'relative' }]);
                } else if (screenTop + topOffset < innerElementOffsetTop) {
                  setStyle([{ height: `${height}px` }, { position: 'fixed', top: `${screenOffset + topOffset}px`, width: `${element.width()}px` }]);
                }
              }
              break;
            }
    
            default: {
              if (maxBottom > bottomPosition && screenBottom > bottomHookValue) {
                if (screenBottom >= maxBottom - bottomPosition + bottomHookValue) {
                  setStyle([{ height: `${height}px` }, { position: 'absolute', top: `${maxBottom - bottomPosition}px`, width: `${element.width()}px` }]);
                } else {
                  setStyle([{ height: `${height}px` }, { position: 'fixed', bottom: `${screenOffset - bottomPosition + bottomHookValue}px`, width: `${element.width()}px` }]);
                }
              }
            }
          }
        }

        scrollTopRef.current = scrollTop;
      }
    };

    setStyle(styleRef.current);

    if (exportResizeCallback) exportResizeCallback.current = callbackRef.current;

    window.addEventListener('scroll', callbackRef.current);
    window.addEventListener('resize', callbackRef.current);
    window.addEventListener('load', callbackRef.current);

    const instance = {
      name,
      get style() { return styleRef.current; },
      lock(isTop) {
        const bbox = elementRef.current.querySelector(':scope > div').getBoundingClientRect();

        if (isTop === true) setStyle([{ height: `${bbox.height}px` }, { position: 'fixed', top: `${bbox.top}px`, width: `${bbox.width}px` }]);
        else if (isTop === false) setStyle([{ height: `${bbox.height}px` }, { position: 'fixed', bottom: `${window.innerHeight - bbox.bottom}px`, width: `${bbox.width}px` }]);

        lock.current = true;
      },
      unlock() {
        const bbox1 = elementRef.current.getBoundingClientRect();
        const bbox2 = elementRef.current.querySelector(':scope > div').getBoundingClientRect();

        setStyle([{ height: `${bbox2.height}px` }, { position: 'absolute', top: `${bbox2.top - bbox1.top}px`, width: `${bbox2.width}px` }]);

        lock.current = false;

        callbackRef.current();
      },
      setStyle,
      callback: callbackRef.current
    }
    stickyInstance.add(instance);

    return () => {
      window.removeEventListener('scroll', callbackRef.current);
      window.removeEventListener('resize', callbackRef.current);
      window.removeEventListener('load', callbackRef.current);

      stickyInstance.remove(instance);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={elementRef} className="sticky-wrapper relative">
      <div className="sticky">
        <ResizeSensible
          callback={(...p) => callbackRef.current && callbackRef.current(...p)}
        >
          {children}
        </ResizeSensible>
      </div>
    </div>
  );
}

export default Sticky;
