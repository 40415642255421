import { IS_SERVER, DIMENSION_UNITS } from 'src/constants';

export default {
  shortValues: { t: 'temperature', ws: 'windSpeed', wh: 'waveHeight', pct: 'precipitation' },
  parse: function(text, units, lang, useWrapper = false) {
    try {
      text = text.replace(/<du .*?>/g, (v) => {
        const prop = this.shortValues[v.match(/ n="(.+?)"/)[1]];
        const val = v.match(/ v="(.+?)"/)[1];

        return this[prop](val, units ? units[prop] : null, lang, useWrapper);
      });
    } catch (ex) {}
    return text;
  },
  temperature: function(value, units, lang, useWrapper) {
    // eslint-disable-next-line default-case
    switch (units) {
      case 'f': value = Math.round(value * 9 / 5 + 32); break;
    }

    value = `${value > 0 ? '+' : ''}${value}°`;

    return useWrapper ? `<span class="inline-block tempr">${value}</span>` : value;
  },
  windSpeed: function(value, units, lang) {
    if (typeof value == 'number') value = value.toString();

    switch (units) {
      case 'km/h': value = value.replace(/[\d.]+/g, (v) => (v  * 3.6).toFixed(1).toString().replace(/\./g, ',')); break;
      case 'm/h': value = value.replace(/[\d.]+/g, (v) => (v  * 2.237).toFixed(1).toString().replace(/\./g, ',')) ; break;
      case 'kn': value = value.replace(/[\d.]+/g, (v) => (v  * 1.944).toFixed(1).toString().replace(/\./g, ',')); break;
      default: value = value.replace(/[\d.]+/g, (v) => (+v).toFixed(1).toString().replace(/\./g, ','));
    }

    return value + (lang ? ' ' + (IS_SERVER ? lang[0](DIMENSION_UNITS.find('windSpeed', units).label, {}, lang[1], 0) : lang.t(DIMENSION_UNITS.find('windSpeed', units).label, null, true)) : '');
  },
  waveHeight: function(value, units, lang) {
    // eslint-disable-next-line default-case
    switch (units) {
      case 'feet': value = value.replace(/\d+/g, (v) => (v / 30.48).toFixed(1).toString().replace(/\./g, ',')); break;
    }

    return value + (lang ? ' ' + (IS_SERVER ? lang[0](DIMENSION_UNITS.find('waveHeight', units).label, {}, lang[1], 0) : lang.t(DIMENSION_UNITS.find('waveHeight', units).label, null, true)) : '');
  },
  precipitation: function(value, units, lang) {
    // eslint-disable-next-line default-case
    switch (units) {
      case 'sm': value = value / 10; break;
      case 'inch': value = value / 25.4; break;
    }

    return (+value).toFixed(1).toString().replace(/\./g, ',') + (lang ? ' ' + (IS_SERVER ? lang[0](DIMENSION_UNITS.find('precipitation', units).label, {}, lang[1], 0) : lang.t(DIMENSION_UNITS.find('precipitation', units).label, null, true)) : '');
  }
};